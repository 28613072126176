import React, { useEffect,Suspense } from 'react';
import { inject, observer } from 'mobx-react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { ActivityIndicator } from 'antd-mobile';
import routes from './router/router';
import NotFound_404 from './notFound/404';
import './App.css';



const routeData = [];
const RouteWithSubRoutes = Menu =>  //这是循环递归路由；
{
    Menu.forEach(item => {
        if (item.routes) {
            RouteWithSubRoutes(item.routes)
        }
        if (!item.hideInMenu) {
            routeData.push(
                <Route key={item.path} exact path={item.path}
                       render={props => {
                           return (
                               <item.component {...props} />
                           )
                       }
                       }
                />
            )
        }
    });
    return routeData
}

function App() {

    useEffect(()=>{

    })

  return (
      <Router>
        <Suspense fallback={<ActivityIndicator toast text="正在加载" />}>
          <Switch>
            <Redirect exact from="/" to="/index" />
              {
                  RouteWithSubRoutes(routes)
              }
            <Route path="*" component={NotFound_404} />
          </Switch>
        </Suspense>
      </Router>
  );
}

export default inject('AppStore')(observer(App));
