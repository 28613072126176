import {makeAutoObservable} from "mobx";


class Todo {
    constructor() {
        makeAutoObservable(this)
    }

    id = Math.random();
    secondsPassed = 0;
    loggedIn = false; //是否登陆
    userInfo = {name: "name"};
    ApiKey = null;

    reset() {
        this.secondsPassed += 1
    }

}

export default new Todo();
