import React from 'react';
import { Result, Icon } from 'antd-mobile';


function App() {



    return (
    <div className="App">
        <Result
            img={<Icon type='ellipsis'/>}
            title="404"
            message="该页面正在开发中，请稍等！"
        />
    </div>
    );
}


export default App;
