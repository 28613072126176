import { lazy } from "react";

// const Index = lazy(() => import('@/view/home/index'));
// const Register = lazy(() => import('@/view/User/Register/index'));
// const Login = lazy(() => import('@/view/User/Login/index'));
const routes = [
  {
    path: "/index",
    name: "首页",
    icon: "home",
    hideInMenu: false,
    component: lazy(() => import("@/view/home/index")),
  },
  {
    path: "/provider",
    name: "服务商入驻",
    icon: null,
    hideInMenu: false,
    component: lazy(() => import("@/view/provider/index")),
    routes: [
      {
        path: "/provider/apply",
        name: "服务商入驻",
        icon: null,
        hideInMenu: false,
        component: lazy(() => import("@/view/provider/providerapply/index")),
      },
      {
        path: "/provider/applysuccess",
        name: "服务商入驻-提交成功",
        icon: null,
        hideInMenu: false,
        component: lazy(() => import("@/view/provider/applysuccess/index")),
      },
      //   {
      //     path: "/provider/apply",
      //     name: "服务商入驻",
      //     icon: null,
      //     hideInMenu: false,
      //     component: lazy(() => import("@/view/provider/apply/index")),
      //   },
      //   {
      //     path: "/provider/pay",
      //     name: "服务商入驻-支付",
      //     icon: null,
      //     hideInMenu: false,
      //     component: lazy(() => import("@/view/provider/pay/index")),
      //   },
    ],
  },
  {
    path: "/card",
    name: "erp名片",
    icon: "user",
    hideInMenu: false,
    component: lazy(() => import("@/view/erp/card/index")),
  },
  {
    path: "/erp/aiAgreement",
    name: "超企云外呼充值服务协议",
    icon: null,
    hideInMenu: false,
    component: lazy(() => import("@/view/erp/aiAgreement/index")),
  },
  {
    path: "/shop/privacy",
    name: "隐私权政策",
    icon: null,
    hideInMenu: false,
    component: lazy(() => import("@/view/shop/privacy/index")),
  },
  {
    path: "/shop/agreement",
    name: "用户协议",
    icon: null,
    hideInMenu: false,
    component: lazy(() => import("@/view/shop/agreement/index")),
  },
  {
    path: "/shop/contact",
    name: "联系我们",
    icon: null,
    hideInMenu: false,
    component: lazy(() => import("@/view/shop/contact/index")),
  },
  {
    path: "/helpCenter",
    name: "帮助中心",
    icon: null,
    hideInMenu: false,
    component: lazy(() => import("@/view/shop/helpCenter/index")),
    routes: [
      {
        path: "/helpCenter/:id",
        name: "用户登录与站内搜素",
        icon: null,
        hideInMenu: false,
        component: lazy(() => import("@/view/shop/helpCenter/content/index")),
      },
    ],
  },
  {
    path: "/shop/about",
    name: "关于我们",
    icon: null,
    hideInMenu: false,
    component: lazy(() => import("@/view/about/index")),
  },
  {
    path: "/shop/service",
    name: "服务列表",
    icon: null,
    hideInMenu: false,
    component: lazy(() => import("@/view/shop/service/index")),
    routes: [
      {
        path: "/shop/service/detail/:id",
        name: "服务详情",
        icon: null,
        hideInMenu: false,
        component: lazy(() => import("@/view/shop/service/detail/index")),
      },
    ],
  },
  {
    path: "/shop/example",
    name: "案例列表",
    icon: null,
    hideInMenu: false,
    component: lazy(() => import("@/view/shop/service/index")),
    routes: [
      {
        path: "/shop/example/detail/:id",
        name: "案例详情",
        icon: null,
        hideInMenu: false,
        component: lazy(() => import("@/view/shop/example/detail/index")),
      },
    ],
  },
  {
    path: "/shop/download",
    name: "扫码下载",
    icon: null,
    hideInMenu: false,
    // component: lazy(() => import('@/view/shop/download'))
    component: lazy(() => import("@/view/shop/download")),
  },
  {
    path: "/qflm/privacy",
    name: "隐私权政策",
    icon: null,
    hideInMenu: false,
    component: lazy(() => import("@/view/qflm/privacy/index")),
  },
  {
    path: "/qflm/registration",
    name: "用户协议",
    icon: null,
    hideInMenu: false,
    component: lazy(() => import("@/view/qflm/registration/index")),
  },
];

export default routes;
